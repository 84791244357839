<template>
  <div class="px-8 py-8 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="UN SBAT Thermal stability test at 75 °C"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Koenen Apparatus Description"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/SBATDetailed-min.png')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/SBATDetailed-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              This test is used to measure the stability of the substance when
              subjected to elevated thermal conditions to determine if the
              substance is unstable in the form it was tested.
            </p>
            <TitleComponent
              class="py-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              Test 3 (c) (i):Thermal stability test at 75 °C, is a variation of
              this test.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>
                      Test temperature of the substance and time of exposure to
                      that temperature
                    </td>
                    <td>
                      Determine the stability of a substance using exposure time
                      and temperatures to mimic potential conditions of
                      transport
                    </td>
                    <td></td>
                    <td>Substance is held at 75°C for 48 hours.</td>
                  </tr>
                  <tr>
                    <td>Sample holder</td>
                    <td>Contain the sample and prevent heat losses</td>
                    <td>
                      <small
                        >Insulated sample containers prevent heat losses that
                        can mask potential self-heating behavior. With greater
                        insulation, any heat given off by the sample is retained
                        thus making it easier to recognize any self-heating.
                        Also, a sealed container can contain gases thereby
                        increasing the pressure. Greater pressures typically
                        correlate with greater reaction rates and this it would
                        be more likely to exhibit any self-heating by the
                        substance. An unsealed sample holder can be more likely
                        to exhibit self-heating if there are any desensitizing
                        agents that can evaporate.</small
                      >
                    </td>
                    <td>
                      Both a sealed an unsealed insulated test tube (13 x 100
                      mm) is used during the test. Each 13 x100 mm tube is
                      surrounded by insulation and placed into a larger tube (25
                      x 100 mm). Each larger glass tube has insulation
                      surrounding it further isolating it thermally from the
                      metal oven block. The glass sample tube can be sealed to
                      prevent the escape of gases. The configuration of each
                      tube including the insulation should result in a
                      temperature decay time constant of 10 minutes as specified
                      in test description in the UN Manual.
                    </td>
                  </tr>
                  <tr>
                    <td>Sample mass</td>
                    <td>
                      Provide sufficient mass to enable accurate detection of
                      thermal instability
                    </td>
                    <td>
                      <small
                        >According to the cardinal rule of explosive safety,
                        individuals should limit exposure to a minimum number of
                        personnel, for a minimum amount of time, and to the
                        minimum amount of material. This test was included in
                        the UN Manual primarily for safety reasons. A reduction
                        from 50 – 100 grams to 5 grams or less is a considerable
                        reduction and represents a safer condition.</small
                      >
                    </td>
                    <td>
                      5 grams of the sample or an amount that fills the tube to
                      75 mm height, whichever is less, is placed inside one of
                      the sample tubes. A second sample tube is filled with the
                      same amount of sample and sealed with a screw cap or other
                      method.
                    </td>
                  </tr>
                  <tr>
                    <td>Test oven</td>
                    <td>
                      Ensure adequate temperature control and protection against
                      thermal runaway
                    </td>
                    <td></td>
                    <td>
                      A well-insulated multiport metal block that can be heated
                      with resistance heaters to a temperature of at least 260
                      °C. The heating of the block must be automated or reliably
                      controlled so that the desired temperature can be
                      maintained within ± 0.5 °C. The heated block should have
                      independent protection against excessively heating the
                      block in the event of a primary control system failure.
                      Each port in the metal block should have a diameter of 5
                      cm and a depth of 10 cm.
                    </td>
                  </tr>
                  <tr>
                    <td>Inert reference</td>
                    <td>
                      Provide a reference for the temperature profile for an
                      inert condition
                    </td>
                    <td></td>
                    <td>
                      An inert reference in the same configuration as the
                      sample(s)
                    </td>
                  </tr>
                  <tr>
                    <td>Self-heating temperature change</td>
                    <td>
                      Parameter for characterizing the thermal stability of a
                      material
                    </td>
                    <td>
                      <small
                        >The 1.5°C criteria with the SBAT configuration matches
                        Test 3 (c) (i)’s ability to detect a minimum heat
                        generation rate of approximately 2 W/kg based on an
                        energy balance of both setups and their thermal
                        characteristics.<br />
                        The term “temperature rise” refers to a 1.5 °C increase
                        over the baseline of the sample temperature. A
                        thermocouple is a differential device: it accurately
                        measures changes in temperature and is less accurate at
                        measuring an absolute temperature. Changes in the sample
                        temperature can be accurately assessed to less than
                        0.25°C (for example National Instruments reports that
                        for their NI 9211 thermocouple input module
                        sensitivities are less than 0.1°C). For an absolute
                        temperature comparison, the accuracy of a thermocouple
                        is typically 1-2 °C depending on multiple factors. The
                        pass/fail criteria is not based on an absolute
                        temperature measurement; it’s based on the change
                        observed in the sample’s temperature<br />
                      </small>
                    </td>
                    <td>
                      The result from a test is considered “+” if either the
                      sealed or unsealed sample shows more than a 1.5 °C
                      temperature rise during the 48-hour test period indicating
                      self-heating.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Ignition or explosion</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Damage to sample holder, flame traces, consumption of
                      sample, etc. Forbidden
                    </td>
                  </tr>
                  <tr>
                    <td>Temperature Increase</td>
                    <td>Thermocouple</td>
                    <td>
                      More than a 1.5 °C temperature rise during the 48-hour
                      test period indicating self-heating. Forbidden.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-3">
            <TitleComponent
              class="py-8"
              title="Example SBAT Results: Explosion (+)"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/SBAT-Go-min.png')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/SBAT-Go-min.png" />
            </a>
            <p class="p-light">
              The blip in both temperature profiles was a power inconsistency.
              The temperature rise of the sample was greater than 1.5°C and thus
              thermally unstable, matching the results observed in the
              instrumented thermal stability test (UN Test 3 (c) (i)).
            </p>
          </div>
        </div>
      </div>
      <UnSideBar class="w-full sm:w-1/5"></UnSideBar>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import UnSideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    UnSideBar
  }
};
</script>

<style lang="scss"></style>
